import { FormGroup } from '@angular/forms';
import { UserStatusDetailsDTO } from '../../../settings/organization/dto/user-status.dto';
import { DesignationDetailsDTO } from '../../../settings/organization/dto/designation.dto';
import { RoleDetailsDTO } from '../../../settings/general-settings/dto/role.dto';
import {
  ColorLabelItemDTO,
  PaginationDTO,
} from '../../../../shared/dto/table.dto';

export interface UserListResponseDTO {
  items: UserListDTO[];
  pagination: PaginationDTO;
}

export interface UserListDTO {
  id: string;
  user: UserMinimalDTO | null;
  name: string;
  initial: string;
  email: string;
  designation_title: string;
  color: string;
  mobile_number: string;
  roles: string;
  user_status: ColorLabelItemDTO | null;
}
export interface UserDTO {
  id: number;
  first_name_en: string;
  last_name_en: string;
  first_name_ar: string;
  last_name_ar: string;
  organization_unit1_title_en: string;
  organization_unit2_title_en: string;
  organization_unit1_title_ar: string;
  organization_unit2_title_ar: string;
  designation_title_en: string;
  designation_title_ar: string;
  roles_en: string;
  roles_ar: string;
  user_status_title_en: string;
  user_status_title_ar: string;
  user_status_color_code: string;
}

export interface UserFormDTO {
  id?: number;
  organization_unit_1_id: number;
  organization_unit_2_id: number;
  organization_unit_3_id: number;
  organization_unit_4_id: number;
  organization_unit_5_id: number;
  designation_id: number;
  first_name_en: string;
  last_name_en: string;
  first_name_ar: string;
  last_name_ar: string;
  email: string;
  mobile_number: string;
  role_ids: number[];
  user_status_color_code?: string;
}

export interface UserMinimalDTO {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  profile_image_path: string | null;
  initial: string;
  color: string;
}

export interface UserDetailsDTO {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  last_login_at: string;
  profile_image_path: string | null;
  initial: string;
  color: string;
  created_at: string;
  updated_at: string;
  organization_unit_1: any;
  organization_unit_2: any | null;
  organization_unit_3: any | null;
  organization_unit_4: any | null;
  organization_unit_5: any | null;
  user_status: UserStatusDetailsDTO | null;
  designation: DesignationDetailsDTO | null;
  roles: RoleDetailsDTO[];
  created_by_user: UserMinimalDTO | null;
  updated_by_user: UserMinimalDTO | null;
}

export interface UserLoginHistoryDTO {
  id: number;
  login_at: string;
  login_status: string;
  ip_address: string;
  location: string;
  device_info: string;
  browser_info: string;
}

export function convertResponseToUserMinimalDTO(
  response: any,
  lang: string = 'en',
  prefix: string = ''
): UserMinimalDTO {
  return {
    id: response.id,
    first_name: response[`${prefix}first_name_${lang}`],
    last_name: response[`${prefix}last_name_${lang}`],
    profile_image_path: response[`${prefix}profile_image_path`],
    initial: response[`${prefix}initial`],
    color: response[`${prefix}color_code`],
  };
}
